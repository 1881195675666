<template>
  <span v-if="user.information">
    <span v-if="!showMultiple">
      <b-button
        class="inlineBtn"
        variant="dark"
        @click="clickSefosMessage"
        v-if="sefosEnabled"
      >
        {{ $t("MESSAGES.COMPOSE") }}
      </b-button>
      <b-button
        class="inlineBtn"
        variant="dark"
        @click="clickSdkMessage"
        v-if="sdkVisible"
      >
        {{ $t("MESSAGES.COMPOSE") }}
      </b-button>
      <b-button
        class="inlineBtn"
        variant="dark"
        @click="clickFaxMessage"
        v-if="faxVisible"
      >
        {{ $t("MESSAGES.COMPOSE") }}
      </b-button>
    </span>
    <span v-if="showMultiple">
      <b-dropdown variant="dark">
        <template #button-content>{{ $t("MESSAGES.COMPOSE") }}</template>
        <b-dropdown-item
          href="#"
          @click="clickSefosMessage"
          v-if="sefosEnabled"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.message')"
            src="/img/message_type/message.png"
          />
          {{ $t("MESSAGES.TYPE.message") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="sdkVisible"
          href="#"
          @click="clickSdkMessage"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.sdk-message')"
            src="/img/message_type/sdk-message.png"
          />
          {{ $t("MESSAGES.TYPE.sdk-message") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="faxVisible"
          href="#"
          @click="clickFaxMessage"
          ><img
            class="icon mr-2"
            :alt="$t('MESSAGES.TYPE.fax-message')"
            src="/img/message_type/fax-message.png"
          />
          {{ $t("MESSAGES.TYPE.fax-message") }}</b-dropdown-item
        >
      </b-dropdown>
    </span>
    <span :key="'AddMessage_' + showIndex">
      <AddSefosMessage
        v-if="sefosEnabled"
        :show="showSefosMessage"
        :user_uuid="user_uuid"
        :message_uuid="message_uuid"
        @ChangeShow="changeShowSefosMessage"
        @SentMessage="SentMessage"
        :functionbox_uuid="functionbox_uuid"
      ></AddSefosMessage>
      <AddSDKMessage
        v-if="sdkVisible"
        :show="showSdkMessage"
        @ChangeShow="changeShowSdkMessage"
        @SentMessage="SentMessage"
        :message_uuid="message_uuid"
        :functionbox_uuid="functionbox_uuid"
      ></AddSDKMessage>
      <AddFaxMessage
        :show="showFaxMessage"
        @ChangeShow="changeShowFaxMessage"
        @SentMessage="SentMessage"
        :message_uuid="message_uuid"
        :functionbox_uuid="functionbox_uuid"
      ></AddFaxMessage>
    </span>
  </span>
</template>
<script>
import AddSefosMessage from "./Message/SefosMessage";
import AddSDKMessage from "./Message/SDKMessage";
import AddFaxMessage from "./Message/FaxMessage";
export default {
  props: {
    message: {
      default: null,
      type: Object,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    },
  },
  components: {
    AddSefosMessage,
    AddSDKMessage,
    AddFaxMessage,
  },
  data() {
    return {
      showSefosMessage: false,
      showSdkMessage: false,
      showFaxMessage: false,
      sefosEnabled: false,
      sdkEnabled: false,
      showIndex: 0,
    };
  },
  methods: {
    clickSdkMessage(){
      this.showSdkMessage = true;
    },
    clickSefosMessage(){
      this.showSefosMessage = true;
    },
    clickFaxMessage(){
      this.showFaxMessage = true;
    },
    async init() {
      this.checkEnabled();
    },
    async checkEnabled() {
      try {
        let result = await this.$http.post(this.user.hostname + "/sefos-message/enabled",{
          organisation_uuid: this.user.information.organisation.organisation_uuid,
          functionbox_uuid: ""
        });
        this.sefosEnabled = result.data;
       } catch {
        console.log("Could not fetch nuiteq enabled");
      }
    },
    changeShowSefosMessage(val) {
      this.showIndex++;
      this.showSefosMessage = val;
      this.$emit('Close');
    },
    changeShowSdkMessage(val) {
      this.showIndex++;
      this.showSdkMessage = val;
      this.$emit('Close');
    },
    changeShowFaxMessage(val) {
      this.showIndex++;
      this.showFaxMessage = val;
      this.$emit('Close');
    },
    SentMessage() {
      this.showIndex++;
      this.$emit("Changed");      
    },
  },
  computed: {
    showMultiple() {
      if (this.functionbox_uuid == "") {
        return false;
      } else {
        if (this.sdkVisible == true && this.faxVisible == true) {
          return true;
        }
        if (this.sdkVisible == true && this.sefosEnabled == true) {
          return true;
        }
        if (this.faxVisible == true && this.sefosEnabled == true) {
          return true;
        }
        return false;
      }
    },
    composeText() {
      return this.$t("MESSAGES.COMPOSE") + " - SEFOS";
    },
    faxVisible() {
      if (this.functionbox_uuid != "") {
        let self = this;
        let fb = this.user.functionboxes.items.filter(function (item) {
          return item.functionbox_uuid == self.functionbox_uuid;
        });
        if (fb.length == 0) {
          return false;
        } else {
          if (fb[0].fax_enabled != undefined) {
            return fb[0].fax_enabled;
          }
        }
      }
      return false;
    },
    sdkVisible() {
      if (this.user.ticket.sdk_enabled) {
        if (this.functionbox_uuid != "") {
          let self = this;
          let fb = this.user.functionboxes.items.filter(function (item) {
            return item.functionbox_uuid == self.functionbox_uuid;
          });
          if (fb.length == 0) {
            return false;
          } else {
            if (fb[0].sdk_enabled != undefined) {
              return fb[0].sdk_enabled;
            }
          }
        }
      }
      return false;
    },
    message_uuid() {
      return this.message ? this.message.message_uuid : "";
    },
  },
  watch: {
    message: function (message) {      
      if (message != null) {
        switch (message.message_type) {
          case "sdk-message":
            this.showSdkMessage = true;
            break;
          case "fax-message":
            this.showFaxMessage = true;
            break;
          default:
            this.showSefosMessage = true;
            break;
        }
      }
    },
  },
  mounted() {
    this.init();
    //this.showSefosMessage = true;
    //this.showFaxMessage = true;
    //this.showSdkMessage = true;
  },
};
</script>
