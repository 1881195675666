var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c("span", [
        !_vm.showMultiple
          ? _c(
              "span",
              [
                _vm.sefosEnabled
                  ? _c(
                      "b-button",
                      {
                        staticClass: "inlineBtn",
                        attrs: { variant: "dark" },
                        on: { click: _vm.clickSefosMessage },
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.$t("MESSAGES.COMPOSE")) +
                            "\n    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.sdkVisible
                  ? _c(
                      "b-button",
                      {
                        staticClass: "inlineBtn",
                        attrs: { variant: "dark" },
                        on: { click: _vm.clickSdkMessage },
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.$t("MESSAGES.COMPOSE")) +
                            "\n    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.faxVisible
                  ? _c(
                      "b-button",
                      {
                        staticClass: "inlineBtn",
                        attrs: { variant: "dark" },
                        on: { click: _vm.clickFaxMessage },
                      },
                      [
                        _vm._v(
                          "\n      " +
                            _vm._s(_vm.$t("MESSAGES.COMPOSE")) +
                            "\n    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.showMultiple
          ? _c(
              "span",
              [
                _c(
                  "b-dropdown",
                  {
                    attrs: { variant: "dark" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [_vm._v(_vm._s(_vm.$t("MESSAGES.COMPOSE")))]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3106679469
                    ),
                  },
                  [
                    _vm.sefosEnabled
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.clickSefosMessage },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                alt: _vm.$t("MESSAGES.TYPE.message"),
                                src: "/img/message_type/message.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.message"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.sdkVisible
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.clickSdkMessage },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                alt: _vm.$t("MESSAGES.TYPE.sdk-message"),
                                src: "/img/message_type/sdk-message.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.sdk-message"))
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.faxVisible
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: { href: "#" },
                            on: { click: _vm.clickFaxMessage },
                          },
                          [
                            _c("img", {
                              staticClass: "icon mr-2",
                              attrs: {
                                alt: _vm.$t("MESSAGES.TYPE.fax-message"),
                                src: "/img/message_type/fax-message.png",
                              },
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("MESSAGES.TYPE.fax-message"))
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "span",
          { key: "AddMessage_" + _vm.showIndex },
          [
            _vm.sefosEnabled
              ? _c("AddSefosMessage", {
                  attrs: {
                    show: _vm.showSefosMessage,
                    user_uuid: _vm.user_uuid,
                    message_uuid: _vm.message_uuid,
                    functionbox_uuid: _vm.functionbox_uuid,
                  },
                  on: {
                    ChangeShow: _vm.changeShowSefosMessage,
                    SentMessage: _vm.SentMessage,
                  },
                })
              : _vm._e(),
            _vm.sdkVisible
              ? _c("AddSDKMessage", {
                  attrs: {
                    show: _vm.showSdkMessage,
                    message_uuid: _vm.message_uuid,
                    functionbox_uuid: _vm.functionbox_uuid,
                  },
                  on: {
                    ChangeShow: _vm.changeShowSdkMessage,
                    SentMessage: _vm.SentMessage,
                  },
                })
              : _vm._e(),
            _c("AddFaxMessage", {
              attrs: {
                show: _vm.showFaxMessage,
                message_uuid: _vm.message_uuid,
                functionbox_uuid: _vm.functionbox_uuid,
              },
              on: {
                ChangeShow: _vm.changeShowFaxMessage,
                SentMessage: _vm.SentMessage,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }