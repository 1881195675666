<template>
  <section v-if="device && user.information">
    <div
      id="wrapper"
      v-bind:class="{ showFolders: showFolders }"
      :key="$route.fullPath"
    >
      <div id="inbox-sidebar" class="h-100">
        <i
          class="fal fa-chevron-right nav-icon clickable"
          v-show="!showFolders"
          @click="showFolders = true"
        ></i>
        <i
          class="fal fa-chevron-left nav-icon clickable"
          v-show="showFolders"
          @click="showFolders = false"
        ></i>

        <MessagesMenu
          :functionbox_uuid="functionbox_uuid"
          :user_uuid="user_uuid"
          :sefosEnabled="sefosEnabled"
          :showFolders="showFolders"
          :is-mobile="device.isMobile"
          @closeFolders="closeFolders"          
        ></MessagesMenu>
        
          <div class="email-text mt-2">
          <b-btn
            :to="{
              name: 'Organisation.Settings.FunctionBox',
              params: { organisation_id: user.information.organisation.id },
            }"
            class="no-border"
            v-if="user.information.is_admin > 0"
          >
            <i class="fal fa-cog"></i><span v-if="showFolders">&nbsp;{{ $t("FUNCTIONBOX.MANAGE") }}</span>
          </b-btn>
        </div>
      
      </div>
      <div id="inbox-content">
        <div v-if="message_uuid == ''" class="w-100">
          <div v-if="showInbox">
            <div class="mb-2 ml-2">
              <span v-if="sefosEnabled"
                ><img
                  class="icon"
                  src="/img/message_type/message.png"
                  :alt="$t('MESSAGES.TYPE.message')"
                />
                {{ boxInformation.name }}, {{ boxInformation.email }}
                <span v-if="boxInformation.fax || boxInformation.sdk">, </span>
              </span>
              <span v-if="boxInformation.fax"
                ><img
                  class="icon"
                  src="/img/message_type/fax-message.png"
                  :alt="$t('MESSAGES.TYPE.fax-message')"
                />
                {{ boxInformation.fax }}
                <span v-if="boxInformation.sdk">, </span>
              </span>
              <span v-if="boxInformation.sdk"
                ><img
                  class="icon"
                  src="/img/message_type/sdk-message.png"
                  :alt="$t('MESSAGES.TYPE.sdk-message')"
                />
                {{ boxInformation.sdk }}</span
              >
            </div>

            <b-form inline :key="'ListMessageForm_' + this.listMessageKeyIndex">
              <AddressBook
                :user_uuid="user_uuid"
                :functionbox_uuid="functionbox_uuid"
                v-slot:activator="{ onClick }"
                v-if="ShowAddressbook"
              >
                <b-button
                  @click="onClick"
                  class="no-border"
                  v-b-tooltip.hover
                  :title="$t('ADDRESSBOOK.TITLE')"
                >
                  <i class="fal fa-address-book"></i
                ></b-button>
              </AddressBook>
              <b-button
                @click="refreshList"
                class="no-border"
                :title="$t('REFRESH')"
                ><i class="fal fa-sync"></i
              ></b-button>

              <AddMessage
                @Close="CloseAddMessage"
                @Changed="Changed"
                :functionbox_uuid="functionbox_uuid"
                :user_uuid="user_uuid"
                :message="message"
              ></AddMessage>

              <label class="sr-only" for="inline-form-input-search">{{
                $t("SEARCH")
              }}</label>
              <b-form-input
                trim
                id="inline-form-input-search"
                class="i-100 searchText hidden-mobile ml-2"
                v-model="searchText"
                :placeholder="$t('SEARCH')"
                @keydown.enter.prevent
              ></b-form-input>
              <label class="sr-only" for="inline-form-input-limit">{{
                $t("LIMIT")
              }}</label>
              <b-form-select
                id="inline-form-input-limit"
                name="messages_limit"
                v-model="limit"
                class="limit hidden-mobile"
              >
                <b-form-select-option value="10">10</b-form-select-option>
                <b-form-select-option value="25">25</b-form-select-option>
                <b-form-select-option value="45">45</b-form-select-option>
                <b-form-select-option value="100">100</b-form-select-option>
              </b-form-select>

              <span v-if="isAdminForFunctionBox == 1">
                <b-button
                  class="no-border ml-2"
                  @click="showEditFunctionBox = true"
                  ><i class="fal fa-cog"></i
                ></b-button>
                <b-sidebar
                  id="user-sidebar"
                  sidebar-class="big_sidebar"
                  right
                  backdrop
                  no-slide
                  shadow
                  v-model="showEditFunctionBox"
                >
                  <div class="px-3 py-2">
                    <FunctionBoxPage
                      edit-users
                      :functionbox_uuid="functionbox_uuid"
                      :key="functionbox_uuid"
                    >
                    </FunctionBoxPage>
                  </div>
                </b-sidebar>
              </span>

              <div class="hidden-not-mobile w-100 mt-2">
                <b-form inline>
                  <label class="sr-only" for="inline-form-input-search">{{
                    $t("SEARCH")
                  }}</label>
                  <b-form-input
                    trim
                    class="ml-2 i-100 searchText"
                    v-model="searchText"
                    :placeholder="$t('SEARCH')"
                    @keydown.enter.prevent
                  ></b-form-input>
                  <label class="sr-only" for="list-limit-messages">{{
                    $t("LIMIT")
                  }}</label>
                  <b-form-select
                    id="list-limit-messages"
                    name="messages_limit"
                    v-model="limit"
                    class="limit"
                  >
                    <b-form-select-option value="10">10</b-form-select-option>
                    <b-form-select-option value="25">25</b-form-select-option>
                    <b-form-select-option value="45">45</b-form-select-option>
                    <b-form-select-option value="100">100</b-form-select-option>
                  </b-form-select>
                </b-form>
              </div>
            </b-form>

            <router-view
              :key="'ListMessage_' + this.listMessageKeyIndex"
              :searchText.sync="searchText"
              :functionbox_uuid="functionbox_uuid"
              :user_uuid="user_uuid"
              :update_message_uuid="update_message_uuid"
              :email="email"
              @changeUpdateMessageUuid="changeUpdateMessageUuid"
              @viewingMessage="viewingMessage"
              @viewingNotification="viewingNotification"
              @draftMessage="DraftMessage"
              :limit.sync="limit"
            ></router-view>
          </div>
        </div>
        <div v-if="message_uuid != ''" class="w-100">
          <Message
            v-if="conversation_message_uuid == ''"
            :message_uuid="message_uuid"
            show-close-btn
            @closeMessage="closeMessage"
            :functionbox_uuid="functionbox_uuid"
            :user_uuid="user_uuid"
            @showConversation="showConversation"
          ></Message>

          <Conversation
            v-if="conversation_message_uuid != ''"
            :message_uuid="conversation_message_uuid"
            :inbox="true"
            :functionbox_uuid="functionbox_uuid"
            @gotoMessage="gotoMessage"
            @closeConversation="closeConversation"
            show-close-btn
            :user_uuid="user_uuid"
          ></Conversation>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MessagesMenu from "@/components/Layout/MessagesMenu";
import AddressBook from "@/components/AddressBook/Page";
import FunctionBoxPage from "@/components/FunctionBox/Page";
import mobileDetect from "mobile-device-detect";
import Conversation from "@/components/Conversation/Conversation";
import Message from "@/components/View/Message.vue";
import AddMessage from "@/components/Compose/AddMessage";
export default {
  props: ["functionbox_uuid", "email", "view_message_uuid", "user_uuid"],
  components: {
    MessagesMenu,
    Message,
    AddMessage,
    Conversation,
    FunctionBoxPage,
    AddressBook,
  },
  data() {
    return {
      device: mobileDetect,
      viewMessage: false,
      showFolders: false,
      searchText: "",
      limit: 45,
      notification: null,
      information: null,
      listMessageKeyIndex: 0,
      message_uuid: this.view_message_uuid,
      conversation_message_uuid: "",
      update_message_uuid: "",
      showEditFunctionBox: false,
      sefosEnabled: false,
      message: null,
    };
  },
  methods: {
    DraftMessage(message) {
      this.message = message;
    },
    ShowFunctionbox(item) {
      if (!this.sefosEnabled) {
        if (item.fax_enabled || item.sdk_enabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    GetSefosEnabled() {
      if (this.user.information) {
        let self = this;
        this.$http
          .post(this.user.hostname + "/settings/get", {
            setting: "SefosMessage",
            functionbox_uuid: "",
            organisation_uuid:
              this.user.information.organisation.organisation_uuid,
          })
          .then(function (result) {
            self.sefosEnabled = result.data.enabled;
          })
          .catch(function () {});
      } else {
        return false;
      }
    },
    gotoMessage(message_uuid) {
      this.notification = null;
      this.conversation_message_uuid = "";
      this.message_uuid = message_uuid;
    },
    closeConversation() {
      this.conversation_message_uuid = "";
    },
    showConversation(message_uuid) {
      this.conversation_message_uuid = message_uuid;
    },
    changeUpdateMessageUuid(update_message_uuid) {
      this.update_message_uuid = update_message_uuid;
    },
    collapse(item) {
      let self = this;
      this.$http
        .post(this.user.hostname + "/user/functionbox/collapse", {
          account_id: this.user.information.id,
          functionbox_uuid: item.functionbox_uuid,
          collapsed: !item.collapse,
        })
        .then(function () {
          for (let ix = 0; ix < self.user.functionboxes.items.length; ix++) {
            if (self.user.functionboxes.items[ix].email == item.email) {
              self.user.functionboxes.items[ix].collapse = !item.collapse;
            }
          }
        })
        .catch(function () {});
    },
    collapseDelegatedBox(item) {
      let self = this;
      this.$http
        .post(this.user.hostname + "/user/delegatedbox/collapse", {
          account_id: this.user.information.id,
          user_uuid: item.user_uuid,
          collapsed: !item.collapse,
        })
        .then(function () {
          for (let ix = 0; ix < self.user.delegatedboxes.items.length; ix++) {
            if (self.user.delegatedboxes.items[ix].email == item.email) {
              self.user.delegatedboxes.items[ix].collapse = !item.collapse;
            }
          }
        })
        .catch(function () {});
    },
    removedBox() {
      this.functionbox_uuid = "";
      this.show_sidebar = false;
      this.getItems();
    },
    closeFolders() {
      if (this.device.isMobile) {
        this.showFolders = false;
      }
      this.listMessageKeyIndex++;
      this.closeConversation();
      this.closeMessage();
    },
    refreshList() {
      this.listMessageKeyIndex++;
    },
    CloseAddMessage() {
      //console.log("CloseAddMessage");
      this.message = null;
      this.listMessageKeyIndex++;
    },
    Changed() {
      this.message = null;
      this.listMessageKeyIndex++;
    },
    viewingMessage(message_uuid) {
      this.update_message_uuid = "";
      this.message_uuid = message_uuid;
      this.notification = null;
    },
    viewingNotification(notification) {
      this.notification = notification;
      this.message_uuid = notification.message_uuid;
    },
    closeMessage() {
      this.update_message_uuid = this.message_uuid;
      this.message_uuid = "";
      this.listMessageKeyIndex++;
    },
    closeNotification() {
      this.notification = null;
    },
    avatarInitials(item) {
      return (
        item.name.charAt(0).toUpperCase() +
        item.name.slice(item.name.length - 1).toUpperCase()
      );
    },
    delegatedKey(index) {
      return "delegated_" + index;
    },
  },
  computed: {
    showInbox() {
      if (this.sefosEnabled == true && this.functionbox_uuid == "") {
        return true;
      }
      if (this.functionbox_uuid != "") {
        return true;
      }
      return false;
    },
    ShowAddressbook() {
      let self = this;
      if (!this.sefosEnabled) {
        let functionbox = this.user.functionboxes.items.filter(function (item) {
          return self.functionbox_uuid == item.functionbox_uuid;
        });
        if (functionbox.fax_enabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    boxInformation() {
      let boxInformationObj = {
        name: "",
        email: "",
        sdk: "",
        fax: "",
      };
      if (this.user_uuid == "" && this.functionbox_uuid == "") {
        boxInformationObj.name = this.user.information.name;
        boxInformationObj.email = this.user.information.email;
      } else {
        if (this.user_uuid != "") {
          for (let ix = 0; ix < this.user.delegatedboxes.items.length; ix++) {
            if (
              this.user.delegatedboxes.items[ix].user_uuid == this.user_uuid
            ) {
              boxInformationObj.name = this.user.delegatedboxes.items[ix].name;
              boxInformationObj.email =
                this.user.delegatedboxes.items[ix].email;
            }
          }
        } else {
          if (this.functionbox_uuid != "") {
            for (let ix = 0; ix < this.user.functionboxes.items.length; ix++) {
              if (
                this.user.functionboxes.items[ix].functionbox_uuid ==
                this.functionbox_uuid
              ) {
                //console.log(this.user.functionboxes.items[ix]);
                boxInformationObj.name = this.user.functionboxes.items[ix].name;
                boxInformationObj.email =
                  this.user.functionboxes.items[ix].email;
                if (
                  this.user.functionboxes.items[ix].sdk_identifier != undefined
                ) {
                  boxInformationObj.sdk =
                    this.user.functionboxes.items[ix].sdk_identifier;
                }
                if (
                  this.user.functionboxes.items[ix].fax_identifier != undefined
                ) {
                  boxInformationObj.fax =
                    this.user.functionboxes.items[ix].fax_identifier;
                }
              }
            }
          }
        }
      }
      return boxInformationObj;
    },
    isAdminForFunctionBox() {
      let self = this;
      if (this.functionbox_uuid == "") {
        return 0;
      }
      if (this.user.information.is_admin > 0) {
        return 1;
      }
      let check = this.user.functionboxes.items.filter(
        (c) => c.functionbox_uuid == self.functionbox_uuid
      );
      if (check.length == 0) {
        return 0;
      } else {
        return check[0].is_admin;
      }
    },
  },
  watch: {
    functionbox_uuid(){
      this.message_uuid = "";
    },
    user_uuid(){
      this.message_uuid = "";
    }
  },
  mounted() {
    this.showFolders = !this.device.isMobile;
    this.GetSefosEnabled();
  },
};
</script>
<style></style>
